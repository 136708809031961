import React, { useState, useEffect, useRef, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {
  colors,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Paper,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import ChatInput from "../components/ChatInput";
import ChatMessages from "../components/ChatMessages";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, models } from "../globals";
import { supabase } from "../supabaseClient";
import io from "socket.io-client";
import AppHeader from "../components/AppHeader";
import Loading from "./Loading";
import PlanDecision from "../components/PlanDecision";
import { useLastChat } from "../contexts/LastChatContext";
import WorkActivityIndicator from "../components/WorkActivityIndicator";
import { useAuth } from "../contexts/AuthContext";
import { useMediaQuery } from "@mui/material";
import Cards from "../components/Cards";
import { steps } from "framer-motion";
import { PlanContext } from "../contexts/PlanContext";
import SampleTasks from "../components/SampleTasks";
import { ListItemText } from "@mui/material";
import { Checkbox } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

export default function NotFound({}) {
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <Typography variant="h1" sx={{ color: "white" }}>
          404
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          Page not found 🫠
        </Typography>
      </Container>
    </ThemeProvider>
  );
}
