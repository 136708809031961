import React, { useState, useEffect } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const CodeBlock = ({ children, className }) => {
  const [copied, setCopied] = useState(false);
  const language = className ? className.replace("language-", "") : "";
  const lines = children?.split("\n");

  const copyCode = () => {
    navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (lines?.length <= 2) {
    return <code className={className}>{children}</code>;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          overflowX: "auto",
          whiteSpace: "pre",
          borderRadius: 1,
          backgroundColor: "action.hover",
          padding: 2,
        }}
      >
        <code className={className}>{children}</code>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          paddingRight: 1,
          paddingTop: 1,
        }}
      >
        {language && (
          <Box
            sx={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginRight: 1,
              textTransform: "uppercase",
            }}
          >
            {language}
          </Box>
        )}
        <Tooltip title={copied ? "Copied!" : "Copy"} placement="top">
          <IconButton size="small" onClick={copyCode}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CodeBlock;
