// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    // Retrieve the current session
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };

    // Subscribe to auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event);
        setSession(session);
      }
    );

    // Periodically refresh the token to prevent expiration
    const intervalId = setInterval(() => {
      supabase.auth.refreshSession().catch(console.error);
    }, 600000); // Refresh every 10 minutes

    // Retrieve the initial session
    getSession();

    // Return a cleanup function that unsubscribes from auth state changes and clears the interval
    return () => {
      authListener.subscription.unsubscribe();
      clearInterval(intervalId);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ session }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);