import React from "react";
import { Typography, Paper, Box, styled } from "@mui/material";

export default function TaskHistory({ taskHistory }) {
  const getStageEmoji = (stage) => {
    switch(stage) {
      case 'PLAN_PENDING':
        return '🛠️';
      case 'PLAN':
        return '✉️';
      case 'CODE':
        return '⏳';
      case 'DONE':
        return '✅';
      default:
        return '';
    }
  };

  const TaskContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.fontWeightBold,
  }));

  const EmojiText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    fontSize: "14pt",
  }));

  const getTaskContent = (task) => {
    if (!task.history || !task.history[0] || !task.history[0].content) {
      return `Empty task at ${task.created_at}`;
    }

    const content = task.history[0].content;
    if (typeof content === 'string') {
      return content.length > 60 ? `${content.slice(0, 60)}...` : content;
    }

    if (typeof content === 'object' && content.data) {
      const data = typeof content.data === 'string' ? content.data : JSON.stringify(content.data);
      return data.length > 60 ? `${data.slice(0, 60)}...` : data;
    }

    return 'Task content unavailable';
  };

  return (
    <>
      {taskHistory.length > 0 && <Typography variant="subtitle1">Threads from earlier</Typography>}
      {taskHistory.length === 0 && (
        <Typography variant="h6" textAlign="center" mt={3}>
          No threads yet
        </Typography>
      )}
      {taskHistory
        .sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        })
        .filter(task => task.history && task.history[0] && task.history[0].content)
        .slice(0, 15)
        .map((task) => (
          <TaskContainer
            key={task.task_id}
            onClick={() => {
              window.location.href = `/chat/${task.task_id}`;
            }}
          >
            <Typography component="span">
              {getTaskContent(task)}
            </Typography>
            <EmojiText variant="body2">{getStageEmoji(task.stage)}</EmojiText>
          </TaskContainer>
        ))}
    </>
  );
}