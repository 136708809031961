import React, { createContext, useState, useCallback } from 'react';

export const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
  const [plan, setPlan] = useState([]);
  const [listeners, setListeners] = useState([]);

  // Function to call to emit updates
  const emitPlanUpdate = useCallback((updatedPlan) => {
    setPlan(updatedPlan);
    console.log('Emitting plan update:', updatedPlan);
    listeners.forEach(listener => listener(updatedPlan)); // Notify all listeners
  }, [listeners]);

  // Function for components to call to register a listener
  const onPlanUpdate = useCallback((callback) => {
    setListeners(currentListeners => [...currentListeners, callback]);
    return () => {
      // Return a cleanup function to remove listener when the component unmounts
      setListeners(currentListeners => currentListeners.filter(listener => listener !== callback));
    };
  }, []);

  return (
    <PlanContext.Provider value={{ emitPlanUpdate, onPlanUpdate }}>
      {children}
    </PlanContext.Provider>
  );
};