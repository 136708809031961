// LoadingCard.js
import React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@emotion/react";

const flashAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export default function LoadingCard() {
  return (
    <Box
      sx={{
        width: "100%",
        height: 100,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: 2,
        animation: `${flashAnimation} 2s ease-in-out infinite`,
      }}
    >
      {[...Array(3)].map((_, index) => (
        <Box
          key={index}
          sx={{
            width: `${60 + Math.random() * 40}%`,
            height: 12,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderRadius: 1,
            marginBottom: 1,
            animation: `${flashAnimation} 1s ease-in-out infinite`,
          }}
        />
      ))}
    </Box>
  );
}