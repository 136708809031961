import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";

const commands = ["/apply", "/remember", "/help"];
const models = [
  { name: "model1", display_name: "Model 1" },
  { name: "model2", display_name: "Model 2" },
];

const MIN_HEIGHT = 100;
const MAX_HEIGHT = 600;

export default function ChatInput({
  onSendMessage,
  isLoading,
  onModelChange,
  showModelSelect,
  onStopGeneration,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedModel, setSelectedModel] = useState(models[0].name);
  const [height, setHeight] = useState(MIN_HEIGHT);
  const resizeRef = useRef(null);
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    setSelectedModel(selectedModel);
    onModelChange(selectedModel);
  };

  const handleSendClick = () => {
    if (inputValue && !isLoading) {
      onSendMessage(inputValue);
      setInputValue("");
    }
  };

  const handleStopClick = () => {
    if (isLoading && onStopGeneration) {
      onStopGeneration();
    }
  };

  const handleResize = (e) => {
    if (e.buttons !== 1) return;
    const startY = e.clientY;
    const startHeight = height;

    const doDrag = (e) => {
      const newHeight = Math.max(MIN_HEIGHT, Math.min(MAX_HEIGHT, startHeight - e.clientY + startY));
      setHeight(newHeight);
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', doDrag);
      document.removeEventListener('mouseup', stopDrag);
    };

    document.addEventListener('mousemove', doDrag);
    document.addEventListener('mouseup', stopDrag);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && inputValue) {
      event.preventDefault();
      onSendMessage(inputValue);
      setInputValue("");
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        height: `${height}px`,
        boxShadow: "0px 0px 10px rgba(0, 128, 128, 0.5)",
        position: "relative",
        zIndex: 1000,
        transition: "height 0.1s",
      }}
    >
      {showModelSelect && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            px: 3,
            pt: 1,
            justifyContent: "start",
          }}
        >
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={selectedModel}
              onChange={handleModelChange}
              variant="standard"
              sx={{
                color: "white",
                "& .MuiSelect-icon": { color: "white" },
                "&:before": { borderBottomColor: "transparent" },
                "&:after": { borderBottomColor: "transparent" },
                "&:hover:before": { borderBottomColor: "transparent" },
              }}
            >
              {models.map((model) => (
                <MenuItem key={model.name} value={model.name}>
                  {model.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box sx={{ 
        display: "flex", 
        flexGrow: 1,
        alignItems: "stretch",
        padding: 1,
      }}>
        <TextField
          fullWidth
          multiline
          inputRef={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Give me a task..."
          variant="outlined"
          sx={{
            flexGrow: 1,
            "& .MuiOutlinedInput-root": { 
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            },
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            "& .MuiInputBase-input": { 
              height: "100% !important", 
              overflowY: "auto",
              cursor: "text",
            },
          }}
        />
        <IconButton
          type="button"
          sx={{ alignSelf: "flex-start" }}
          aria-label={isLoading ? "stop" : "send"}
          onClick={isLoading ? handleStopClick : handleSendClick}
        >
          {isLoading ? <StopCircleIcon /> : <SendIcon />}
        </IconButton>
      </Box>
      <Box
        ref={resizeRef}
        onMouseDown={handleResize}
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          cursor: "ns-resize",
          padding: "5px",
          color: "white",
          "&:hover": {
            color: "rgba(255, 255, 255, 0.8)",
          },
        }}
      >
        <DragHandleIcon />
      </Box>
    </Paper>
  );
}