import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import './FloatingTabSwitch.css'; // Assuming this file contains your original CSS

const blinkAnimation = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const NotificationDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #AFD0CE;
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  animation: ${blinkAnimation} 2s infinite;
`;

const FloatingTabSwitch = ({ activeTab, setActiveTab, tabWithNotification = null }) => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientY < 100 || tabWithNotification) {
        setIsVisible(true);
        clearTimeout(timeoutRef.current);
      } else if (!componentRef.current?.contains(e.target)) {
        startHideTimer();
      }
    };

    const handleScroll = () => {
      if (window.scrollY < 100 || tabWithNotification) {
        setIsVisible(true);
        clearTimeout(timeoutRef.current);
      } else {
        startHideTimer();
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);

    setIsVisible(!!tabWithNotification);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tabWithNotification]);

  const startHideTimer = () => {
    if (!tabWithNotification) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setIsVisible(false), 3000);
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    if (!tabWithNotification) {
      startHideTimer();
    }
  };

  return (
    <div
      ref={componentRef}
      className={`floating-tab-switch ${isVisible ? 'visible' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="tabs-container">
        <div className={`highlight ${activeTab === 'Chat' ? 'chat' : 'studio'}`}></div>
        {['Chat', 'Studio'].map((tab) => (
          <button
            key={tab}
            className={`tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            <span className="tab-content">
              {tab}
              {tabWithNotification === tab && <NotificationDot />}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FloatingTabSwitch;