/* global HockeyStack */
// Chat.js
import React, { useState, useEffect, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {
  colors,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import ChatInput from "../components/ChatInput";
import ChatMessages from "../components/ChatMessages";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, models } from "../globals";
import { supabase } from "../supabaseClient";
import io from "socket.io-client";
import AppHeader from "../components/AppHeader";
import Loading from "./Loading";
import PlanDecision from "../components/PlanDecision";
import { useLastChat } from "../contexts/LastChatContext";
import WorkActivityIndicator from "../components/WorkActivityIndicator";
import Gallery from "../components/Gallery";
import PlaygroundHeader from "../components/PlaygroundHeader";
import SampleTasks from "../components/SampleTasks";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

export default function Sandbox({}) {
  const [task_id, setTaskId] = useState(null);
  const [socket, setSocket] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [liveResponse, setLiveResponse] = useState(null);

  async function signInAndCreateTask() {
    // Attempt to sign up the user.
    let email = "sandbox@fumedev.com";
    let password = "makesomething";

    let { data, error: signInError } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    console.log("data", data);

    // If there's an error in signing up, log it and alert the user.
    if (signInError) {
      console.log(signInError);
      alert("You don't have access to this account.");
      return;
    }

    let authToken = data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.post(`${API_URL}/start_task`, null, {
        headers: headers,
      });
      console.log("task_response", response);
      setAuthToken(authToken);
      setTaskId(response.data.task_id);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    signInAndCreateTask();
  }, []);

  useEffect(() => {
    if (authToken && task_id) {
      console.log("Connecting to socket", task_id);
      const socket_temp = io(
        `${API_URL.replace("http://", "ws://").replace(
          "https://",
          "wss://"
        )}/chat`,
        {
          query: { task_id: task_id },
          extraHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setSocket(socket_temp);

      console.log("Getting available repos", task_id);
      getUser();
      getTask();
      getAvailableRepos();
    }
  }, [authToken, task_id]);

  useEffect(() => {
    if (socket) {
      console.log("Socket connected", task_id);
      // Listen for incoming messages
      socket.on("message", (message) => {
        console.log("New Message: ", message);
        if (message.data.type === "chunk") {
          setLiveResponse((prevLiveResponse) => {
            if (prevLiveResponse) {
              return {
                ...prevLiveResponse,
                content: {
                  ...prevLiveResponse.content,
                  data: prevLiveResponse.content.data + message.data.data,
                },
              };
            } else {
              return {
                id: Date.now(),
                content: {
                  type: "liveResponse",
                  data: message.data.data,
                },
              };
            }
          });
        } else {
          setLiveResponse(null);
          const message_obj = {
            role: "assistant",
            content: message.data,
          };
          setMessages((prevMessages) => [...prevMessages, message_obj]);
          if (!message.data.wait) {
            setIsLoading(false);
          }
        }
      });

      // Clean up on component unmount or when task_id changes
      return () => {
        socket.off("message");
        socket.disconnect();
      };
    } else {
      console.log("Socket disconnected");
    }
  }, [socket, task_id]);

  const [user, setUser] = useState({});
  const [task, setTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState();
  const [availableRepos, setAvailableRepos] = useState([]);
  const [selectedModel, setSelectedModel] = useState(models[0].name);

  const [isReady, setIsReady] = useState(false);

  const bottomRef = useRef(null);

  function formatRepos() {
    let res = [];
    for (let i = 0; i < availableRepos.length; i++) {
      let r = availableRepos[i];
      if (r.documentation_state === "SUCCESS") {
        switch (r.repo_name) {
          case "FumePlayground/manim":
            res.push({
              id: r.id,
              title: "Manim",
              subtitle:
                "Animation engine for Python from our favourite YouTube channel 3Blue1Brown",
              src: "manim.png",
              sample_tasks: [
                "What is this codebase for?",
                "How can I make the background of the animations customizable?",
              ],
            });
            break;
          case "FumePlayground/superagent":
            res.push({
              id: r.id,
              title: "Superagent",
              subtitle:
                "Open-source AI-agents for doing web research by day, friendly neighborhood ninja by night.",
              src: "superagent.png",
              sample_tasks: [
                "What does SuperAgent do?",
                "I want to add a tool to fetch SEC data using Edgartools",
                "How is authentication handled?",
                "Is there an options to switch between different LLM APIs in the UI?",
              ],
            });
            break;
          case "FumePlayground/OpenCopilot":
            res.push({
              id: r.id,
              title: "OpenCopilot",
              subtitle:
                "Open source text-to-actions engine. Let users talk to your product.",
              src: "opencopilot.png",
              sample_tasks: [
                "What is OpenCopilot for?",
                "Could you explain how background tasks are managed within the llm-server directory?",
                "How is chat functionality implemented in the OpenCopilot codebase?",
                "How can I add OpenCopilot to my web app?",
              ],
            });
            break;
          case "FumePlayground/django":
            res.push({
              id: r.id,
              title: "Django",
              subtitle:
                "A high-level Python Web framework that encourages rapid development.",
              src: "django.png",
              sample_tasks: [
                "Can you layout the structure of this codebase for me?",
                "What are some good features I can add to this codebase?",
              ],
            });
            break;
          case "FumePlayground/fastapi":
            res.push({
              id: r.id,
              title: "FastAPI",
              subtitle:
                "A modern, fast (high-performance), web framework for building APIs",
              src: "fastapi.png",
              sample_tasks: [
                "What testing strategies and types of tests are implemented in the tests folder?",
                "How is exception handling managed in the FastAPI code modules?",
                "https://github.com/tiangolo/fastapi/issues/11251",
                "https://github.com/tiangolo/fastapi/issues/11037"
              ],
            });
            break;
          case "FumePlayground/quivr":
            res.push({
              id: r.id,
              title: "Quivr",
              subtitle:
                "Your AI Second Brain. Only one that has more stars than them is the man in the sky.",
              src: "quivr.png",
              sample_tasks: [
                "https://github.com/QuivrHQ/quivr/issues/2304",
                "What is Quivr for?",
                "How can I self-host Quivr?",
              ],
            });
            break;
          case "FumePlayground/E2B":
            res.push({
              id: r.id,
              title: "E2B",
              subtitle:
                "Cloud runtime for building AI agents. Secure sandboxed cloud environments for AI agents",
              src: "e2b.png",
              sample_tasks: [
                "What is this codebase for?",
                "How are different services like database management and reverse-proxy optimization integrated into the system?",
                "How is the sandbox functionality setup to handle saving of Matplotlib figures?",
              ],
            });
            break;
        }
      }
    }
    return res;
  }

  const handleModelChange = async (newModel) => {
    setSelectedModel(newModel);

    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.put(
        `${API_URL}/select_model`,
        { task_id: task_id, model_name: newModel },
        {
          headers: headers,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  function resetStates() {
    setMessage("");
    setMessages([]);
    setTask({});
    setAuthToken(null);
    setIsLoading(false);
    setLiveResponse(null);
  }

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAvailableRepos() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    console.log("API", API_URL);

    try {
      const response = await axios.get(`${API_URL}/repos`, {
        headers: headers,
      });

      if (response.data) {
        console.log("response.data", response.data);
        setAvailableRepos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/chat/${task_id}`, {
        headers: headers,
      });

      if (response.data) {
        setTask(response.data);
        console.log("Task", response.data);
        if (!messages) {
          setMessages(response.data.history);
        }
        setIsReady(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function approvePlan() {
    try {
      socket.emit(
        "message",
        JSON.stringify({
          task_id: task_id,
          action: "approve_plan",
          model_name: selectedModel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function rejectPlan() {
    try {
      socket.emit(
        "message",
        JSON.stringify({
          task_id: task_id,
          action: "reject_plan",
          model_name: selectedModel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  const sendMessage = (message) => {
    if (typeof HockeyStack !== "undefined")
      HockeyStack.goal("Playground Send Message", { message: message });
    setMessage(message);
    console.log("Sending message:", message);
    if (message.trim()) {
      setIsLoading(true);
      const message_obj = {
        task_id: task_id,
        message: message,
        model_name: selectedModel,
      };
      const msg = {
        role: "user",
        content: { data: message, type: "chat_message" },
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      socket.emit("message", JSON.stringify(message_obj));
      setMessage("");
    }
  };

  const createPRAction = () => {
    let message = "/apply";
    console.log("Sending message:", message);
    if (message.trim()) {
      setIsLoading(true);
      const message_obj = {
        task_id: task_id,
        message: message,
        model_name: selectedModel,
      };
      const msg = {
        role: "user",
        content: { data: message, type: "chat_message" },
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      socket.emit("message", JSON.stringify(message_obj));
      setMessage("");
    }
  };

  const hasNoMessages = !messages || messages?.length === 0;

  const [selectedRepo, setSelectedRepo] = useState(null);

  async function confirmSelectedRepo(selectedRepo) {
    if (typeof HockeyStack !== "undefined")
      HockeyStack.goal("Playground Task Start", {
        selected_repo: selectedRepo,
      });
    
    let repos = formatRepos();
    for (let i = 0; i < repos.length; i++) {
      if (repos[i].id === selectedRepo) {
        setSelectedRepo(repos[i]);
        break;
      }
    }

    setIsReady(false);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    try {
      const response = await axios.put(
        `${API_URL}/select_repo`,
        {
          task_id: task_id,
          repo_id: selectedRepo,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response);
      getTask();
    } catch (error) {
      console.log(error);
    }
  }

  const [planProposed, setPlanProposed] = useState(false);

  useEffect(() => {
    if (task_id && authToken) {
      getTask();

      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (messages?.length > 0) {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.content.type === "plan") {
          setPlanProposed(true);
        } else {
          setPlanProposed(false);
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    console.log("task", task);
  }, [task]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <PlaygroundHeader user={user ? user : null} />
      <Container
        component="main"
        maxWidth="md"
        sx={{ width: "100%", height: "100%", padding: 5 }}
      >
        <CssBaseline />
        {!isReady && <Loading />}
        {isReady && hasNoMessages && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column", // Adjust the height as needed
            }}
          >
            {task?.selected_repo?.id ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Let's accelerate {task.selected_repo.repo_name}!
                </Typography>
                <Paper
                  elevation={3}
                  sx={{ mt: 2, p: 2, width: "100%", textAlign: "center" }}
                >
                  <Typography variant="body1">
                    Ask me anything about the codebase! It can either be task or
                    a question.
                    <br />
                    <br />
                    You can copy and paste error logs and I will analyze the
                    codebase to suggest a possible solution.
                    <br />
                    <br />
                    Whenver you are ready to implement the changes,{" "}
                    <strong>type '/apply'</strong> and I will submit a pull
                    request for it. Or you can copy and paste the changes too if
                    you don't want to wait for me to write code (I'm a little
                    slow at the moment)
                  </Typography>
                </Paper>
              </Box>
            ) : (
              <>
                <Typography variant="h5" component="h2" textAlign="center">
                  Select one of our favourites!
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  mb={5}
                >
                  Open buffet of our gourmet open source project selections
                </Typography>
                <Gallery
                  repos={formatRepos()}
                  selectRepo={confirmSelectedRepo}
                />
              </>
            )}
          </Box>
        )}
        {!hasNoMessages && (
          <Box sx={{ width: "100%" }}>
            <ChatMessages
              messages={messages}
              createPRAction={createPRAction}
              liveResponse={liveResponse}
              sendMessage={sendMessage}
            />
            <div ref={bottomRef} />
          </Box>
        )}
        {task?.selected_repo?.id && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              maxWidth: "md",
              display: "flex",
              justifyContent: "center",
              marginBottom: "-10px",
            }}
          >
            {planProposed ? (
              <PlanDecision
                onYes={approvePlan}
                onNo={rejectPlan}
                isLoading={isLoading}
              />
            ) : task.stage === "PLAN_PENDING" || task.stage === "CODE" ? (
              <WorkActivityIndicator />
            ) : hasNoMessages ? (
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <SampleTasks
                  tasks={selectedRepo?.sample_tasks}
                  handleTaskClick={sendMessage}
                />
                <ChatInput
                  onSendMessage={sendMessage}
                  isLoading={isLoading}
                  onModelChange={handleModelChange}
                  showModelSelect={false}
                />
              </Box>
            ) : (
              <ChatInput
                onSendMessage={sendMessage}
                isLoading={isLoading}
                onModelChange={handleModelChange}
                showModelSelect={false}
              />
            )}
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
