import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Box,
  TextField,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import { Add } from "@mui/icons-material";

const AddStudioModal = ({ open, onClose, onSuccess }) => {
  const [selectedRepo, setSelectedRepo] = useState("");
  const [name, setName] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let body = { display_name: name };
      let res = await axios.post(`${API_URL}/studio/create`, body, { headers });
      if (res.status != 200) {
        alert("You have you have reached the maximum number of studios for your account. Contact contact@fumedev.com to upgrade your account.");
        console.log(res)
      }
      onSuccess()
      onClose();

    } catch (error) {
      console.error("Error creating studio:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create a New Studio</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Studios are persistent development environments that Fume can re-use
          across multiple tasks. You can setup multiple codebases or sign in to
          online services, just like your local development environment.
        </Typography>
        <TextField
          label="Studio Name"
          variant="outlined"
          placeholder="Give a name to this studio"
          fullWidth
          value={name}
          onChange={handleNameChange}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            mx: 2,
            mb: 2,
            py: 2,
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "teal",
              color: "white",
            },
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStudioModal;
