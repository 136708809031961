// File Path: src/components/OnboardingModal.js

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function OnboardingModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="onboarding-modal-title"
      aria-describedby="onboarding-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="onboarding-modal-title" variant="h6" component="h2">
          Welcome to Fume!
        </Typography>
        <Typography id="onboarding-modal-description" sx={{ mt: 2 }}>
          In order to start using Fume, you will need to connect your GitHub or GitLab account to your Fume account.
          <br/>
          <br/>
          This will allow you to access the code and documentation for your projects. Please refer to the instructions in our documentation to connect your accounts.
        </Typography>
        <Button
          onClick={ () => window.open('https://docs.fumedev.com/docs') }
          sx={{ mt: 3, mb: 2 }}
          variant="contained"
        >
          See the Docs
        </Button>
      </Box>
    </Modal>
  );
}
