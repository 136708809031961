
import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { supabase } from '../supabaseClient';
import { API_URL } from '../globals';

const SubscriptionCheckWrapper = ({ children }) => {
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [open, setOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const handleClose = () => setOpen(false);

  const handlePortalClick = async () => {
    const emailParam = encodeURIComponent(userEmail);
    const stripeUrl = `https://pay.fumedev.com/p/login/8wM5nl8Ltcuyego144?prefilled_email=${emailParam}`;
    window.open(stripeUrl, '_blank'); // '_blank' used to open in a new tab
  }

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = (await supabase.auth.getUser()).data.user;
      if (user && user.email) {
        console.log('Email:', user.email);
        setUserEmail(user.email);
      }
    };
    

    fetchUserDetails();
    const checkSubscription = async () => {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const response = await fetch(API_URL+'/user/check_subscription', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setIsSubscribed(data.subscribed);
        if (!data.subscribed) {
          setOpen(true);
        }
      } else {
        console.error('Failed to fetch the subscription status');
        setIsSubscribed(false);
        setOpen(true);
      }
    };
    checkSubscription();

  }, []);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      {!isSubscribed && (
        <Modal 
          open={open} 
          aria-labelledby="subscription-modal-title"
          aria-describedby="subscription-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="subscription-modal-title" variant="h6" component="h2" align="center">
              Subscription Inactive
            </Typography>
            <Typography id="subscription-modal-description" variant="body1" sx={{ mt: 2 }} align="center">
              Your subscription has expired. Please update your payment details to continue using Fume.
            </Typography>
            <Button onClick={handlePortalClick}
                      sx={{ mt: 3, mb: 2 }}
                      variant="contained"
            >
              Go to Billing
            </Button>
          </Box>
        </Modal>
      )}
      {isSubscribed && children}
    </>
  );
};

export default SubscriptionCheckWrapper;
