import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Chat from "./pages/Chat";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./pages/Settings";
import Sandbox from "./pages/Sandbox";
import { LastChatProvider } from "./contexts/LastChatContext";
import { Analytics } from "@vercel/analytics/react";
import GithubCallback from "./components/GithubCallback";
import SubscriptionCheckWrapper from "./components/SubscriptionCheckWrapper";
import SuperAgent from "./pages/SuperAgent";
import { AuthProvider } from "./contexts/AuthContext";
import { PlanProvider } from "./contexts/PlanContext";
import NotFound from "./pages/404";
import { ON_PREM } from "./globals";
import Studio from "./pages/Studio/Studio";
import DataSourcesPage from "./components/DataSoucresPage";

function App() {
  console.log("ON_PREM", ON_PREM);
  useEffect(() => {
    document.title = "Fume | AI SWE";
    // Add the Hockeystack script if not ON_PREM
    if (!ON_PREM) {
      const hsscript = document.createElement("script");
      hsscript.src =
        "https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack.min.js";
      hsscript.async = true;
      hsscript.dataset.apikey = "4177b25dd8a4d4ebe8560537b1cb26";
      hsscript.dataset.cookieless = 1;
      hsscript.dataset.autoIdentify = 1;
      document.head.append(hsscript);
    }
  }, []);

  const getStudioComponent = () => {
    const studioId = window.location.pathname.split("/studio/")[1];
    return <Studio studioId={studioId} editMode={true} />;
  };

  return (
    <Router>
      <div>
        <AuthProvider>
          <LastChatProvider>
            <Routes>
              <Route path="/tkpyjktnm5zh8e66n5hbj2idk154da/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/" element={<ProtectedRoute element={Home} />} />
              <Route
                path="/chat/:task_id?"
                element={
                  <PlanProvider>
                    {" "}
                    {/* Wrap Chat with PlanProvider */}
                    <ProtectedRoute
                      element={Chat}
                      key={window.location.pathname}
                    />
                  </PlanProvider>
                }
              />
              <Route
                path="/settings"
                element={<ProtectedRoute element={Settings} />}
              />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="/playground" element={<Sandbox />} />
              <Route path="/github/callback" element={<GithubCallback />} />
              <Route path="/superagent" element={<SuperAgent />} />
              <Route path="/studio/:id?" element={<Studio editMode={true} />} />
            </Routes>
          </LastChatProvider>
        </AuthProvider>
      </div>
      {!ON_PREM && <Analytics />}
    </Router>
  );
}

export default App;
