// AddRepoModal.js
import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "@mui/material";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL , ON_PREM} from "../globals";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: colors.teal,
  },
});

export default function AddRepoModal({ open, onClose }) {
  const navigate = useNavigate();
  const [gitProvider, setGitProvider] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [repositoryName, setRepositoryName] = useState("");
  const [developmentBranch, setDevelopmentBranch] = useState("");
  const [gitlabAccessToken, setGitlabAccessToken] = useState("");
  const [devopsAccessToken, setDevopsAccessToken] = useState("");
  const [devopsProjectName, setDevopsProjectName] = useState("");
  const [githubToken, setGithubToken] = useState("");
  const [gitlabDomain, setGitlabDomain] = useState("");
  const [bitbucketToken, setBitbucketToken] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [organizationNameError, setOrganizationNameError] = useState(false);
  const [repositoryNameError, setRepositoryNameError] = useState(false);
  const [developmentBranchError, setDevelopmentBranchError] = useState(false);
  const [gitlabAccessTokenError, setGitlabAccessTokenError] = useState(false);
  const [gitlabDomainError, setGitlabDomainError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noGithubToken, setNoGithubToken] = useState(false);

  let modalCloseTimer;

  const handleGitProviderChange = (event) => {
    setGitProvider(event.target.value);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true); // Set the isLoading state to true
    setErrorMessage(null); // Reset the error message
    event.preventDefault();
    if (
      gitProvider &&
      organizationName &&
      repositoryName &&
      developmentBranch &&
      (gitProvider !== "GITLAB" || (gitlabAccessToken && gitlabDomain)) &&
      (gitProvider !== "BITBUCKET" || bitbucketToken)
    ) {
      if (gitProvider === "GITHUB") {
        if(!ON_PREM){
          const formData = {
            gitProvider,
            organizationName,
            repositoryName,
            developmentBranch,
          };
          localStorage.setItem("formData", JSON.stringify(formData));
  
        //Get current user's email from supabase
        const user = supabase.auth.getUser();
        const userEmail = (await user).data.user.email;
      
  
        // If the user doesn't have a GitHub token, open a new tab with the GitHub login URL
        const loginUrl = `${API_URL}/github/login?repo_owner=${organizationName}&repo_name=${repositoryName}&branch_name=${developmentBranch}&user_email=${userEmail}`;
        window.open(loginUrl, '_blank');
        setIsLoading(false); // Set the isLoading state to false
        onClose(); // Close the modal
        return;
        }else{
          const authToken = (await supabase.auth.getSession()).data.session
          .access_token;
          const headers = { Authorization: `Bearer ${authToken}` };
          const body = {
            git_provider: gitProvider,
            repo_name: `${organizationName}/${repositoryName}`,
            branch: developmentBranch,
            gitlab_token: "",
            gitlab_domain: ""
          };
          try {
            const res = await axios.post(`${API_URL}/repo`, body, {
              headers,
            });
  
            if (res.status === 200) {
              console.log(res);
              handleCloseModal();
            }
          } catch (error) {
            console.log(error);
          }
          
        }
       

      }else if (gitProvider === "BITBUCKET") {
        const authToken = (await supabase.auth.getSession()).data.session
          .access_token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const body = {
          git_provider: gitProvider,
          repo_name: `${organizationName}/${repositoryName}`,
          branch: developmentBranch,
          gitlab_token: "",
          gitlab_domain: "",
          bitbucket_token: bitbucketToken,
        };

        try {
          const res = await axios.post(`${API_URL}/repo`, body, {
            headers,
          });

          if (res.status === 200) {
            console.log(res);
            handleCloseModal();
          }
        } catch (error) {
          console.log(error);
        }
      } else if (gitProvider === "GITLAB") {
        const authToken = (await supabase.auth.getSession()).data.session
          .access_token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const body = {
          git_provider: gitProvider,
          repo_name: `${organizationName}/${repositoryName}`,
          branch: developmentBranch,
          gitlab_token: gitlabAccessToken,
          gitlab_domain: gitlabDomain,
          bitbucket_token: "",
        };

        try {
          const res = await axios.post(`${API_URL}/repo`, body, {
            headers,
          });

          if (res.status === 200) {
            console.log(res);
            handleCloseModal();
          }
        } catch (error) {
          console.log(error);
        }
      } else if (gitProvider === "AZURE_DEVOPS") {
        const authToken = (await supabase.auth.getSession()).data.session
          .access_token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const body = {
          git_provider: gitProvider,
          repo_name: `${organizationName}/${repositoryName}`,
          branch: developmentBranch,
          azure_devops_token: devopsAccessToken,
          azure_devops_project_name: devopsProjectName,
          bitbucket_token: "",
        };

        try {
          const res = await axios.post(`${API_URL}/repo`, body, {
            headers,
          });

          if (res.status === 200) {
            console.log(res);
            handleCloseModal();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setErrorMessage("Please fill in all the required fields.");

    }
  };

  const handleGithubTokenSubmit = async (event) => {
    setIsLoading(true); // Set the isLoading state to true
    event.preventDefault();
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.put(
        `${API_URL}/update_user_github_token`,
        {
          github_token: githubToken,
        },
        {
          headers: headers,
        }
      );

      if (response.data) {
        console.log(response);
        setGithubToken("");
        setIsLoading(false);
        setNoGithubToken(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setErrorMessage(null); // Reset the error message
    setSubmitted(false); // Reset the submitted state
    setIsLoading(false); // Reset the isLoading state
    onClose(); // Call the onClose prop to close the modal
  };

  const startModalCloseTimer = () => {
    modalCloseTimer = setTimeout(() => {
      handleCloseModal();
    }, 3000); // Close the modal after 10 seconds
  };

  function renderErrorMessage(errorMessage) {
    if (errorMessage === "'GithubApp' object has no attribute 'installation'") {
      return (
        <>
          The Fume GitHub app is not installed on your account. Please install
          it{" "}
          <a
            href="https://github.com/apps/fumedev/installations/select_target"
            target="_blank"
            style={{ color: "inherit" }}
          >
            here
          </a>
          .
        </>
      );
    } else {
      return "There was an error adding the repository. Please check your spelling and capitalization try again later.";
    }
  }

  const noGithubTokenForm = (
    <Box sx={modalStyle}>
      <Typography id="add-repo-modal-title" variant="h6" color={"white"}>
        Add you Githun Token
      </Typography>
      <Typography
        id="add-repo-modal-description"
        sx={{ mt: 2, color: "white" }}
      >
        You need to add your GitHub token to be able access your repos. You can
        obtain your access token{" "}
        <a
          style={{ color: colors.teal[200] }}
          href="https://github.com/settings/tokens/new"
          target="_blank"
        >
          here
        </a>
        . This will redirect you to Github page for creating a 'classic token'.
        Make sure not to create a 'fine-grained' one. Here are the permissions
        you need to add to your token:{" "}
        <strong style={{ color: colors.teal[200] }}>
          repo, admin:org, admin:repo_hook, admin:org_hook, user
        </strong>
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ mt: 3 }}
        onSubmit={handleGithubTokenSubmit}
      >
        <Grid container>
          <Grid width="100%">
            <TextField
              required
              fullWidth
              id="githubToken"
              label="Github Token"
              name="githubToken"
              autoComplete="github-token"
              onChange={(e) => {
                setGithubToken(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {errorMessage && (
              <Box sx={{ mt: 2 }}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const regularForm = (
    <Box sx={modalStyle}>
      <Typography id="add-repo-modal-title" variant="h6" color={"white"}>
        Add a Repository
      </Typography>
      <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="git-provider-label">Git Provider</InputLabel>
              <Select
                labelId="git-provider-label"
                id="git-provider-select"
                value={gitProvider}
                label="Git Provider"
                onChange={handleGitProviderChange}
              >
                <MenuItem value="GITHUB">Github</MenuItem>
                <MenuItem value="GITLAB">GitLab</MenuItem>
                <MenuItem value="BITBUCKET">BitBucket</MenuItem>
                <MenuItem value="AZURE_DEVOPS">Azure DevOps</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="organizationName"
              required
              fullWidth
              id="organizationName"
              label="Organization Name"
              autoFocus
              error={organizationNameError}
              onChange={(e) => {
                setOrganizationName(e.target.value);
                setOrganizationNameError(false);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="repositoryName"
              label="Repository Name"
              name="repositoryName"
              error={repositoryNameError}
              onChange={(e) => {
                setRepositoryName(e.target.value);
                setRepositoryNameError(false);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="developmentBranch"
              label="Development Branch"
              name="developmentBranch"
              autoComplete="development-branch"
              helperText="This is the name of the branch that you do the development on i.e. dev or development etc."
              error={developmentBranchError}
              onChange={(e) => {
                setDevelopmentBranch(e.target.value);
                setDevelopmentBranchError(false);
              }}
            />
          </Grid>
          {gitProvider === "GITLAB" && (
            <>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="gitlabAccessToken"
                  label="GitLab Access Token"
                  name="gitlabAccessToken"
                  autoComplete="gitlab-access-token"
                  error={gitlabAccessTokenError}
                  onChange={(e) => {
                    setGitlabAccessToken(e.target.value);
                    setGitlabAccessTokenError(false);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="gitlabDomain"
                  label="GitLab Domain"
                  name="gitlabDomain"
                  autoComplete="gitlab-domain"
                  error={gitlabDomainError}
                  onChange={(e) => {
                    setGitlabDomain(e.target.value);
                    setGitlabDomainError(false);
                  }}
                />
              </Grid>
            </>
          )}
          {gitProvider === "AZURE_DEVOPS" && (
            <>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="devopsAccessToken"
                  label="Azure DevOps Personal Access Token"
                  name="devopsAccessToken"
                  autoComplete="devops-access-token"
                  error={gitlabAccessTokenError}
                  onChange={(e) => {
                    setDevopsAccessToken(e.target.value);
                    setGitlabAccessTokenError(false);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="devopsProjectName"
                  label="Azure DevOps Project Name"
                  name="devopsProjectName"
                  autoComplete="devops-project-name"
                  error={gitlabDomainError}
                  onChange={(e) => {
                    setDevopsProjectName(e.target.value);
                    setGitlabDomainError(false);
                  }}
                />
              </Grid>
            </>
          )}
          {gitProvider === "BITBUCKET" && (
            <>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="repositoryAccessToken"
                  label="Repository Access Token"
                  name="repositoryAccessToken"
                  autoComplete="repo-access-token"
                  error={gitlabAccessTokenError}
                  onChange={(e) => {
                    setBitbucketToken(e.target.value);
                    setGitlabAccessTokenError(false);
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {/* Modal content goes here */}
            {errorMessage && (
              <Box sx={{ mt: 2 }}>
                <Typography color="error">
                  {renderErrorMessage(errorMessage)}
                </Typography>
              </Box>
            )}
            {submitted ? (
              <Typography variant="body1" align="center" color={"white"}>
                You have successfully added a new repository. It will take a
                moment for us to process it. You can check on the status from
                Settings &gt; Data Sources.
              </Typography>
            ) : isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="add-repo-modal-title"
        aria-describedby="add-repo-modal-description"
      >
        {noGithubToken ? noGithubTokenForm : regularForm}
      </Modal>
    </ThemeProvider>
  );
}