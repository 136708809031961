// ChatInput.js
import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function ChatInput({ onYes, onNo, isLoading }) {
  return (
    <Paper
      sx={{
        p: "16px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxShadow: "0px 0px 10px rgba(0, 128, 128, 0.5)",
      }}
    >
      <Typography variant="body1" gutterBottom>
        Does this plan seem good to you?
      </Typography>
      <Box sx={{ display: "flex", gap: "16px", mt: "16px" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onYes}
          disabled={isLoading}
        >
          Yes
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={onNo}
          disabled={isLoading}
          sx={{
            color: "white",
            borderColor: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderColor: "white",
            },
          }}
        >
          No
        </Button>
      </Box>
    </Paper>
  );
}
