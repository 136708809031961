import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { supabase } from "../../../supabaseClient";
import { API_URL } from "../../../globals";
import Alert from "@mui/material/Alert";
import { CircularProgress, Button, IconButton } from "@mui/material";
import axios from "axios";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import CheckIcon from "@mui/icons-material/Check";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import FumeConfigForm from "../../../components/FumeConfigForm";

const CodeEditor = ({ fileName, onCodeChange, studioId }) => {
  const [value, setValue] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleCodeChange = (newValue) => {
    setValue(newValue);
    onCodeChange(newValue);
    setIsModified(newValue !== initialValue);
  };

  const getFileContent = async () => {
    setLoading(true);
    console.log(studioId);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.get(
        `${API_URL}/studio/get-file?studioId=${studioId}&filePath=${fileName}`,
        {
          headers: headers,
        }
      );
      console.log(response.status);
      if (response.status !== 200) {
        setBannerMessage("Error loading file");
        return;
      }
      if (!response.data) {
        setValue("");
        setInitialValue("");
        setBannerMessage("File is empty");
        return;
      }

      const fileContent =
        typeof response.data === "string"
          ? response.data
          : JSON.stringify(response.data, null, 2);

      setValue(fileContent);
      setInitialValue(fileContent);
      setBannerMessage("");
    } catch (error) {
      setBannerMessage("Error loading file");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLanguageExtension = (fileName) => {
    const extension = fileName.split(".").pop();
    switch (extension) {
      case "js":
        return javascript();
      case "py":
        return python();
      case "html":
        return html();
      case "css":
        return css();
      default:
        return [];
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.post(
        `${API_URL}/studio/save-file`,
        {
          studio_id: studioId,
          file_path: fileName,
          content: value,
        },
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        setBannerMessage("Error saving file");
        return;
      }
      setSaveSuccessful(true);
      setIsModified(false);
      setInitialValue(value);
      setBannerMessage("");
      setTimeout(() => setSaveSuccessful(false), 3000);
    } catch (error) {
      setBannerMessage("Error saving file");
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRevert = () => {
    setValue(initialValue);
    setIsModified(false);
  };

  useEffect(() => {
    if (fileName) {
      getFileContent();
    }
  }, [fileName]);

  return (
    <div
      style={{
        borderRadius: "20px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {loading && (
        <CircularProgress style={{ color: "#fff", margin: "auto" }} />
      )}
      {!loading && (
        <>
          {fileName && (
            <div
              style={{
                backgroundColor: "transparent",
                padding: "10px",
                fontFamily: "monospace",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundColor: "#212121",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  borderRadius: "20px",
                }}
              >
                {fileName}
              </div>
              <div>
                {isModified && (
                  <>
                    <IconButton
                      onClick={handleSave}
                      style={{ color: "#fff" }}
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <SaveOutlinedIcon />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={handleRevert}
                      style={{ color: "#fff" }}
                      disabled={isSaving}
                    >
                      <RestorePageOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          )}
          {fileName == "./.fumeconfig" ? (
            <FumeConfigForm studioId={studioId} initialState={value} />
          ) : (
            <CodeMirror
              value={value}
              theme={"dark"}
              height="90vh"
              display="flex"
              extensions={getLanguageExtension(fileName)}
              onChange={handleCodeChange}
              options={{
                theme: "dark",
                indentWithTab: true,
                smartIndent: true,
                lineWiseCopyCut: true,
                rectangularSelection: true,
              }}
            />
          )}
        </>
      )}
      {!loading && (bannerMessage || saveSuccessful) && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
          {bannerMessage && (
            <Alert
              icon={<FileOpenOutlinedIcon fontSize="inherit" />}
              severity="info"
              style={{ textAlign: "center" }}
            >
              {bannerMessage}
            </Alert>
          )}
          {saveSuccessful && (
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
              style={{ textAlign: "center" }}
            >
              File saved successfully
            </Alert>
          )}
        </div>
      )}
    </div>
  );
};

export default CodeEditor;
