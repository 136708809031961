import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Box,
  TextField,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";

const AddBoardMappingModal = ({ open, onClose, repositories, baseUrl }) => {
  const [selectedRepo, setSelectedRepo] = useState("");
  const [boardKey, setBoardKey] = useState("");

  function getRepoId(repoName) {
    return repositories.find((repo) => repo.repo_name === repoName)?.id;
  }

  const handleRepoChange = (event) => {
    const { value } = event.target;
    setSelectedRepo(value);
  };

  const handleBoardKeyChange = (event) => {
    setBoardKey(event.target.value);
  };

  const handleSubmit = async () => {
    console.log("Submitting:", selectedRepo, boardKey);
    let repoId = getRepoId(selectedRepo);
    if (!repoId) {
      console.log("Repository not found:", selectedRepo);
      return;
    }
    try {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let body = { base_url: baseUrl, board_key: boardKey, repo_id: repoId };
      await axios.post(`${API_URL}/add-jira-mapping`, body, { headers });
    } catch (error) {
      console.error("Error adding board mapping:", error);
    }
    onClose(); // Close the modal after submission
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Board Mapping</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Configure your board mapping by selecting repositories and specifying
          a board key.
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Repository</InputLabel>
          <Select
            value={selectedRepo}
            onChange={handleRepoChange}
            input={<OutlinedInput id="select-repository" label="Repository" />}
            renderValue={(selected) => selected}
          >
            {repositories.map((repo) => (
              <MenuItem key={repo.repo_name} value={repo.repo_name}>
                {repo.repo_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Board Key"
          variant="outlined"
          fullWidth
          value={boardKey}
          onChange={handleBoardKeyChange}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            mx: 2,
            mb: 2,
            py: 2,
            backgroundColor: "background.paper",
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBoardMappingModal;
