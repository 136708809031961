// ChatMessages.js
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ReactMarkdown from "react-markdown";
import { colors } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import Tooltip from "@mui/material/Tooltip";
import SearchCard from "./SearchCard";
import CodeBlock from "./CodeBlock";
import LoadingCard from "./LoadingCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Linkify from "react-linkify";
import PullRequestPreview from "./PullRequestPreview";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import LinkPreviewCard from "./LinkPreviewCard";
import CodeIcon from "@mui/icons-material/Code"; // Icon for "Show Code Snippets"
import CheckList from "@mui/icons-material/Checklist"; // Icon for "Explain More"
import SummarizeIcon from "@mui/icons-material/Summarize"; // Icon for "Summarize"
import CreateIcon from "@mui/icons-material/Create"; // Icon for "Create PR"

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

function renderChatMessage(message, isLastMessage) {
  if (!message.content?.data) {
    return
  }
  
  if (message.content.type === "chat_message") {
    const urlRegex =
      /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const urls = message.content?.data?.match(urlRegex) || []; // Extract URLs from the message

    return (
      <Box
        key={message.id}
        sx={{
          width: "100%",
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          <ReactMarkdown>
            {message.role == "user" ? "You" : "Fume"}
          </ReactMarkdown>
        </Typography>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
            animation: isLastMessage ? "blink 2s infinite" : "none",
            "@keyframes blink": {
              "0%": { backgroundColor: "#212121" },
              "50%": { backgroundColor: "#6e6d6a" },
              "100%": { backgroundColor: "#212121" },
            },
          }}
        >
          <Typography variant="body1">
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  if (inline) {
                    return <code className={className}>{children}</code>;
                  }
                  return (
                    <CodeBlock className={className}>{children}</CodeBlock>
                  );
                },
              }}
            >
              {message.content.data}
            </ReactMarkdown>
          </Typography>
        </Paper>
        {urls.map((url, index) => (
          <LinkPreviewCard key={index} url={url} />
        ))}
      </Box>
    );
  } else if (message.content.type === "snippets") {
    if (message.content.data.length === 0) {
      return null;
    }
    return <SearchCard message={message} />;
  } else if (message.content.type === "chat_message") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          <ReactMarkdown>Fume</ReactMarkdown>
        </Typography>
        <Paper sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="body1">
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  if (inline) {
                    return <code className={className}>{children}</code>;
                  }
                  return (
                    <CodeBlock className={className}>{children}</CodeBlock>
                  );
                },
              }}
            >
              {message.data.snippets}
            </ReactMarkdown>
          </Typography>
        </Paper>
      </Box>
    );
  } else if (message.content.type === "plan") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          <ReactMarkdown>Plan</ReactMarkdown>
        </Typography>
        <Paper sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="body1">
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  if (inline) {
                    return <code className={className}>{children}</code>;
                  }
                  return (
                    <CodeBlock className={className}>{children}</CodeBlock>
                  );
                },
              }}
            >
              {message.content.data}
            </ReactMarkdown>
          </Typography>
        </Paper>
      </Box>
    );
  } else if (message.content.type === "plan_approval") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Paper
          sx={{ p: 2, borderRadius: 2, backgroundColor: colors.green[100] }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon sx={{ color: colors.green[500], mr: 1 }} />
            <Typography variant="body1" sx={{ color: colors.green[800] }}>
              The plan has been approved.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  } else if (message.content.type === "plan_rejection") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Paper sx={{ p: 2, borderRadius: 2, backgroundColor: colors.red[100] }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CancelIcon sx={{ color: colors.red[500], mr: 1 }} />
            <Typography variant="body1" sx={{ color: colors.yellow[800] }}>
              The plan has been rejected. Please provide feedback to fix it.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  } else if (message.content.type === "pr_link") {
    const prLink = message.content.data;

    return <PullRequestPreview prLink={prLink} />;
  } else if (message.content.type === "error") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Paper sx={{ p: 2, borderRadius: 2, backgroundColor: colors.red[100] }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CancelIcon sx={{ color: colors.red[500], mr: 1 }} />
            <Typography variant="body1" sx={{ color: colors.red[800] }}>
              Sorry, an error occurred.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  } else if (message.content.type === "tool_call") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            animation: isLastMessage ? "blink 2s infinite" : "none",
            "@keyframes blink": {
              "0%": { backgroundColor: "#212121" },
              "50%": { backgroundColor: "#6e6d6a" },
              "100%": { backgroundColor: "#212121" },
            },
          }}
        >
          <BlurOnIcon sx={{ mr: 1, color: colors.teal[200] }} />
          <Typography variant="body1">
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  if (inline) {
                    return <code className={className}>{children}</code>;
                  }
                  return (
                    <CodeBlock className={className}>{children}</CodeBlock>
                  );
                },
              }}
            >
              {message.content.data.query}
            </ReactMarkdown>
          </Typography>
        </Paper>
      </Box>
    );
  } else if (message.content.type === "liveResponse") {
    return (
      <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          <ReactMarkdown>Fume</ReactMarkdown>
        </Typography>
        <Paper sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="body1">
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  if (inline) {
                    return <code className={className}>{children}</code>;
                  }
                  // Check if the code block is complete before rendering
                  if (
                    children &&
                    typeof children[0] === "string" &&
                    children[0].startsWith("```") &&
                    children[0].endsWith("```")
                  ) {
                    return (
                      <CodeBlock className={className}>{children}</CodeBlock>
                    );
                  }
                  // If the code block is incomplete, render it as inline code
                  return <code className={className}>{children}</code>;
                },
              }}
            >
              {message.content.data}
            </ReactMarkdown>
          </Typography>
        </Paper>
      </Box>
    );
  } else {
    console.log("Unknown message", message);
  }
}

export default function ChatMessages({
  messages,
  createPRAction,
  liveResponse,
  sendMessage,
}) {
  const [lastMessage, setLastMessage] = useState(null);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [isPlanTyping, setIsPlanTyping] = useState(false);
  const [showCreatePRButton, setShowCreatePRButton] = useState(false);
  const bottomRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);

  function scrollToBottom() {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    if (messages.length > 0) {
      setLastMessage(messages[messages.length - 1]);
    }
  }, [messages]);

  useEffect(() => {
    console.log("MESSAGES", messages)
    if (lastMessage) {
      // a bool variable to check if there any message with message.content.type === "plan"
      const hasPlanMessage = messages.some(
        (message) => message.content.type === "plan"
      );
      setIsAssistantTyping(
        (lastMessage.content.wait || lastMessage.role === "user") &&
          !liveResponse
      );
      setIsPlanTyping(lastMessage.content.type === "plan");
      setShowCreatePRButton(
        !lastMessage.content.wait &&
          lastMessage.role !== "user" &&
          !hasPlanMessage
      );
    }
  }, [lastMessage, liveResponse]);

  useEffect(() => {
    scrollToBottom();
  }, [isAssistantTyping]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        mt: 4,
        mb: 0,
        mb: isPlanTyping ? 24 : 20,
      }}
    >
      {messages.map((message, index) =>
        renderChatMessage(message, index === messages.length - 1 && !liveResponse && message.content.type === "tool_call")
      )}
      {isAssistantTyping && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <LoadingCard />
        </Box>
      )}
      {liveResponse && renderChatMessage(liveResponse, true)}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            textAlign={"center"}
          >
            Are you sure Fume has all of the necessary information to complete
            the task? This action will tell Fume to make a final plan and write
            code.
          </Typography>
          <Button
            onClick={() => {
              createPRAction();
              setOpenModal(false);
            }}
            sx={{ mt: 2, width: "100%" }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </Box>
      </Modal>
      <div ref={bottomRef} />
    </Box>
  );
}
