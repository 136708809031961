import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useParams } from "react-router-dom";
import FileExplorer from "./components/FileExplorer";
import { Code } from "@mui/icons-material";
import Terminal from "./components/Terminal";
import CodeEditor from "./components/CodeEditor";
import { Paper, Typography, Box, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { VncScreen } from "react-vnc";
import TerminalWindow from "./components/Terminal";
import { API_URL } from "../../globals";
import { supabase } from "../../supabaseClient";
import Loading from "../../pages/Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

const Studio = forwardRef(({ studioId: propStudioId, editMode }, ref) => {
  const localRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const studioId = propStudioId || id; // Use studioId from prop or fallback to the one from URL params
  const [vncURL, setVncURL] = useState("");
  const [studioData, setStudioData] = useState({});
  const [activeFile, setActiveFile] = useState("");
  const [fumeTerminalContent, setFumeTerminalContent] = useState("");
  const [refresh, setRefresh] = useState(0);

  useImperativeHandle(ref, () => ({
    updateStudiData(message) {
      console.log("Studio method called!");
      if (message.type === "file_edit" || message.type === "create_file" || message.type === "get_definition" || message.type === "get_file_content") {
        console.log("File action: ", message.data);
        setActiveFile(message.data.file_path);
      } else if (message.type === "look_on_process" || message.type === "run_command") {
        console.log("Look on process: ", message.data);
        setFumeTerminalContent(fumeTerminalContent + message.data.output);
      }
    },
  }));

  const triggerRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const saveStudioHandler = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    const body = {
      studio_id: studioId,
    };
    const response = await axios.post(`${API_URL}/studio/save-studio`, body, {
      headers: headers,
    });
    navigate(`/settings`);
  };

  useEffect(() => {
    const fetchVncInfo = async () => {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await fetch(
        `${API_URL}/studio/info?studio_id=${studioId}`,
        {
          headers: headers,
        }
      );
      const res_data = await response.json();
      const instance_name = res_data.instance_name;
      const vncURL =  `wss://vnc.${instance_name}.fumedev.com`
      setVncURL(vncURL);
      console.log(res_data);
      setStudioData(res_data);
    };
    fetchVncInfo();
  }, []);

  const handleFileChange = (fileName) => {
    setActiveFile(fileName);
  };

  if (studioData.status !== "READY") {
    return Loading;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          background:
            "radial-gradient(circle at 80% 20%, #004D40 0%, #000000 25%)",
          height: "100vh",
          display: "flex",
        }}
      >
        <PanelGroup direction="horizontal">
          <Panel
            defaultSize={15}
            maxSize={25}
            style={{
              height: "100vh",
              color: "#d4d4d4",
              borderColor: "#2f2f2f",
              borderStyle: "solid",
              borderWidth: 1,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                height: "95vh",
                padding: "10px",
                marginTop: "10px",
                marginLeft: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" gutterBottom>
                File Explorer
              </Typography>
              <FileExplorer
                studioId={studioId}
                changeEditor={handleFileChange}
                refresh={refresh}
              />
              {editMode && (
                <Button
                  style={{
                    marginTop: "auto",
                    width: "100%",
                    backgroundColor: "#628F8C",
                  }}
                  onClick={saveStudioHandler}
                  variant="contained"
                >
                  Save Studio
                </Button>
              )}
            </Box>
          </Panel>
          <PanelResizeHandle />
          <Panel
            defaultSize={45}
            style={{
              background:
                "radial-gradient(at 30% 70%, rgba(78, 128, 224, 0.5), transparent 40%)",
              height: "100vh",
              borderColor: "#2f2f2f",
              borderStyle: "solid",
              borderWidth: 1,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                height: "95vh",
                padding: "10px",
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <CodeEditor
                fileName={activeFile}
                onCodeChange={(code) => console.log(code)}
                studioId={studioId}
              />
            </Box>
          </Panel>
          <PanelResizeHandle />
          <Panel style={{ height: "100vh", paddingLeft: "10px" }}>
            <PanelGroup direction="vertical">
              <Panel
                defaultSize={60}
                style={{
                  borderRadius: "10px",
                  marginTop: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                {vncURL && (
                  <VncScreen
                    url={vncURL}
                    rfbOptions={{
                      credentials: {
                        password: studioData?.vnc_password.substring(0, 8),
                      },
                    }}
                    scaleViewport
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                    ref={localRef}
                  />
                )}
              </Panel>
              <PanelResizeHandle />
              <Panel defaultSize={40}>
                <Box sx={{ height: "90%", marginBottom: "0px" }}>
                  <TerminalWindow
                    studioId={studioId}
                    refreshStudio={triggerRefresh}
                    fumeTerminalContent={fumeTerminalContent}
                  />
                </Box>
              </Panel>
              <PanelResizeHandle />
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </Box>
    </ThemeProvider>
  );
});

export default Studio;
