// AppHeader.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Button
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import the CloseIcon
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { supabase } from "../supabaseClient";
import { colors } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function PlaygroundHeader() {
  const navigate = useNavigate();

  const navigateToLastChatOrHome = () => {
    window.location.href = `https://fumedev.com/`;
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        background:
          "linear-gradient(to bottom, rgba(18, 18, 18, 1) 0%, rgba(66, 66, 66, 0) 100%)",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label={"close"}
          onClick={navigateToLastChatOrHome}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="p"
          component="div"
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          <img src="/logo.png" width="100px" />
          PLAYGROUND
        </Typography>
        <Button variant="contained" style={{backgroundColor: colors.teal[300]}} onClick={() => navigate("/signup")}>Sign Up</Button>
      </Toolbar>
    </AppBar>
  );
}
