import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

const Gallery = ({repos, selectRepo}) => {

  return (
    <Grid container spacing={3} justifyContent="center">
      {repos.map((repo) => (
        <Grid item xs={repos.length === 1 ? 12 : 4} sm={repos.length === 1 ? 12 : 6} md={4} key={repo.id} style={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{boxShadow: "0px 0px 5px rgba(0, 128, 128, 0.75)"}} onClick={() => selectRepo(repo.id)}>
            <CardMedia
              component="img"
              height="200"
              image={repo.src}
              alt={repo.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {repo.title}
              </Typography>
              <Typography gutterBottom variant="body" component="div">
                {repo.subtitle}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Gallery;