import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Card,
  CardContent,
  Modal,
  Button,
  Divider,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import { colors } from "@mui/material";

const CodeModal = ({ open, onClose, filePath, codeSnippets }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        maxHeight: "80%",
        overflowY: "auto",
        bgcolor: colors.grey[900],
        boxShadow: 24,
        p: 4,
        outline: "none",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {filePath?.split("/").slice(4).join("/")}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {codeSnippets?.map((snippet, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Snippet {index + 1}:
          </Typography>
          <Paper
            sx={{
              p: 2,
              bgcolor: colors.grey[800],
              color: colors.grey[100],
              borderRadius: 1,
              whiteSpace: "pre-wrap",
              fontFamily: "monospace",
            }}
          >
            {snippet}
          </Paper>
        </Box>
      ))}
    </Box>
  </Modal>
);

const SearchCard = ({ message }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const mergedData = Array.isArray(message.content.data.snippets)
    ? message.content.data.snippets.reduce((acc, item) => {
        const existingItem = acc.find((i) => i.file_path === item.file_path);
        if (existingItem) {
          existingItem.code.push(item.code);
        } else {
          acc.push({ file_path: item.file_path, code: [item.code] });
        }
        return acc;
      }, [])
    : [];

  const handleCardClick = (filePath, codeSnippets) => {
    setSelectedFile({ path: filePath, snippets: codeSnippets });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFile(null);
  };

  return (
    <Box key={message.id} sx={{ width: "100%", mb: 2 }}>
      <Paper
        sx={{ p: 2, borderRadius: 2, overflowX: "auto", whiteSpace: "nowrap" }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          {mergedData?.map((item, index) => {
            const truncatedPath = item.file_path.split("/").slice(4).join("/");
            return (
              <Card
                key={index}
                sx={{
                  minWidth: 200,
                  maxWidth: 300,
                  backgroundColor: colors.grey[900],
                  color: colors.grey[100],
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
                onClick={() => handleCardClick(item.file_path, item.code)}
              >
                <CardContent sx={{ py: 1 }}>
                  <Typography variant="subtitle1" noWrap>
                    {item.file_path.split("/").pop()}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={colors.grey[400]}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {truncatedPath}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      </Paper>
      <CodeModal
        open={modalOpen}
        onClose={handleModalClose}
        filePath={selectedFile?.path}
        codeSnippets={selectedFile?.snippets}
      />
    </Box>
  );
};

export default SearchCard;
