import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AppHeader({
  user,
  startTask,
  taskHistory,
  lastVisitedChat,
  pos,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation(); // Use location to check the current route
  const isHomePage = location.pathname === "/"; // Check if the current page is Home
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToLastChatOrHome = () => {
    if (isHomePage && lastVisitedChat) {
      navigate(`/chat/${lastVisitedChat}`);
    } else if (!isHomePage) {
      navigate("/");
    }
  };

  const sidebar = (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
        },
        display: "flex",
        flexDirection: "column",
      }}
      open
    >
      <Box sx={{ p: 2, py: 4, borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
        <img
          src="/logo.png"
          alt="Logo"
          style={{ width: "50%", marginBottom: "16px" }}
        />
      </Box>
      <List sx={{ flexGrow: 1 }}>
        <ListItem onClick={startTask}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Start New" />
        </ListItem>
        <ListItem onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
      <Box
        sx={{ mt: "auto", borderTop: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}
      >
        <ListItem onClick={() => navigate("/settings")}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: colors.teal[200] }} onClick={handleClick}>
              {user?.full_name &&
                user?.full_name
                  .split(" ")
                  .slice(0, 2)
                  .map((name) => name[0])}
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={user?.full_name} />
        </ListItem>
      </Box>
    </Drawer>
  );

  return (
    <div>
      {isLargeScreen && !isHomePage && pos !== "top" ? (
        sidebar
      ) : (
        <AppBar
          position="fixed"
          top={0}
          sx={{
            background:
              "linear-gradient(to bottom, rgba(18, 18, 18, 1) 0%, rgba(66, 66, 66, 0) 100%)",
            boxShadow: "none",
            width: "100%",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label={isHomePage ? "close" : "menu"}
              onClick={navigateToLastChatOrHome}
            >
              {isHomePage ? <CloseIcon /> : <MenuIcon />}{" "}
              {/* Show CloseIcon if on Home page */}
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="add"
              sx={{ ml: 1 }}
            >
              <AddIcon onClick={() => startTask()} />
            </IconButton>
            <Typography
              variant="p"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              <img src="/logo.png" width="100px" />
              BETA
            </Typography>
            <Avatar sx={{ bgcolor: colors.teal[200] }} onClick={handleClick}>
              {user?.full_name &&
                user?.full_name
                  .split(" ")
                  .slice(0, 2)
                  .map((name) => name[0])}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem
                onClick={async () => {
                  const { error } = await supabase.auth.signOut();
                  if (error) {
                    console.log(error);
                    alert("Oops, something went wrong.");
                    return;
                  }
                  localStorage.clear();
                  navigate("/signin");
                }}
              >
                Sign Out
              </MenuItem>
              <MenuItem onClick={() => navigate("/settings")}>
                Settings
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )}
    </div>
  );
}
