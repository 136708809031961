/* global HockeyStack */
// Chat.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import TaskList from "../components/TaskList";
import {
  colors,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Paper,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import ChatInput from "../components/ChatInput";
import ChatMessages from "../components/ChatMessages";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, models } from "../globals";
import { supabase } from "../supabaseClient";
import io from "socket.io-client";
import AppHeader from "../components/AppHeader";
import Loading from "./Loading";
import PlanDecision from "../components/PlanDecision";
import { useLastChat } from "../contexts/LastChatContext";
import WorkActivityIndicator from "../components/WorkActivityIndicator";
import { useAuth } from "../contexts/AuthContext";
import { useMediaQuery } from "@mui/material";
import Cards from "../components/Cards";
import { steps } from "framer-motion";
import { PlanContext } from "../contexts/PlanContext";
import SampleTasks from "../components/SampleTasks";
import { ListItemText } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FloatingTabSwitch from "../components/FloatingTabSwitch/FloatingTabSwitch";
import Studio from "./Studio/Studio";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

export default function Chat({}) {
  const { task_id } = useParams();
  const [socket, setSocket] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [liveResponse, setLiveResponse] = useState(null);
  const [cards, setCards] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const { onPlanUpdate } = useContext(PlanContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [floatingTabNotification, setFloatingTabNotification] = useState(null);
  const { session } = useAuth();

  useEffect(() => {
    if (!task_id) {
      navigate("/notfound");
    }
  }, []);

  useEffect(() => {
    if (session) {
      setAuthToken(session.access_token);
    }
  }, [session]);

  useEffect(() => {
    if (authToken && task_id) {
      console.log("Connecting to socket", task_id);
      const socket_temp = io(
        `${API_URL.replace("http://", "ws://").replace(
          "https://",
          "wss://"
        )}/chat`,
        {
          query: { task_id: task_id },
          extraHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setSocket(socket_temp);

      getUser();
      getTask();
      getMenuOptions();
    }
  }, [authToken, task_id]);

  const studioRef = useRef();

    const callStudioMethod = (message) => {
        if (studioRef.current) {
            studioRef.current.updateStudiData(message);
        }
    };

  useEffect(() => {
    if (socket) {
      console.log("Socket connected", task_id);
      // Listen for incoming messages
      socket.on("message", (message) => {
        console.log("New Message: ", message);
        if (message.data.type === "chunk") {
          setLiveResponse((prevLiveResponse) => {
            if (prevLiveResponse) {
              return {
                ...prevLiveResponse,
                content: {
                  ...prevLiveResponse.content,
                  data: prevLiveResponse.content.data + message.data.data,
                },
              };
            } else {
              return {
                id: Date.now(),
                content: {
                  type: "liveResponse",
                  data: message.data.data,
                },
              };
            }
          });
        } else if (message.data.type === "plan_card") {
          // Handle 'plan_card' type message to update cards
          console.log("Plan Card: ", message.data.data);
          const steps = message.data.data.steps;
          setIsLoadingCards(false);
          setCards(steps);
        } else if (message.data.type === "plan_card_loading") {
          // Handle 'plan_card_loading' type message to toggle card loading state
          setIsLoadingCards(true);
        } else if (message.data.type == "task_list") {
          setTaskList(message.data.data);
        } else if (message.data.type === "file_edit" || message.data.type === "create_file" || message.data.type === "get_definition" || message.data.type === "look_on_process" || message.data.type === "run_command" || message.data.type === "go_to_url" || message.data.type === "get_file_content") {
          console.log("Message for studio: ", message.data);
          callStudioMethod(message.data);
          //set the floating tab notification to Studio for 5 seconds - revert it back to null after 5 seconds but do it async
          setFloatingTabNotification("Studio");
          setTimeout(() => {
            setFloatingTabNotification(null);
          }, 20000);
        }
        else {
          setLiveResponse(null);
          const message_obj = {
            role: "assistant",
            content: message.data,
          };
          setMessages((prevMessages) => [...prevMessages, message_obj]);
          if (!message.data.wait) {
            setIsLoading(false);
          }
        }
      });
      

      // Clean up on component unmount or when task_id changes
      return () => {
        socket.off("message");
        socket.disconnect();
      };
    } else {
      console.log("Socket disconnected");
    }
  }, [socket, task_id]);

  useEffect(() => {
    if (socket) {
      // Function to handle plan updates
      const handlePlanUpdate = (updatedPlan) => {
        console.log("Plan updated, emitting changes:", updatedPlan);
        // Emitting updated plan to the backend or through WebSocket
        socket.emit(
          "message",
          JSON.stringify({
            task_id: task_id,
            action: "update_plan",
            plan: { steps: updatedPlan },
          })
        );
      };

      // Registering the listener for plan updates
      const unsubscribe = onPlanUpdate(handlePlanUpdate);

      // Cleanup function to unsubscribe when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [socket, onPlanUpdate]);

  const [user, setUser] = useState({});
  const [task, setTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState();
  const [selectedDataSource, setSelectedDataSource] = useState(0);
  const [availableRepos, setAvailableRepos] = useState([]);
  const [options, setOptions] = useState([]);
  const [studios, setStudios] = useState([]);
  const [selectedModel, setSelectedModel] = useState(models[0].name);
  const theme = useTheme();

  const [isReady, setIsReady] = useState(false);
  const { setLastVisitedChat } = useLastChat(); // Use the hook to get the setLastVisitedChat function

  // Sample tasks array could potentially be fetched or derived from state
  console.log("task", task);
  const tasks = task.studio
    ? []
    : task.selected_repo
    ? [
        ...(task.context_repos.length > 0
          ? task.context_repos.map((repo) => ({
              label: `${repo.split("-")[0]}/${repo.split("-")[1]}`,
            }))
          : []),
        { label: `${task.selected_repo.repo_name}` },
        { label: "+ Add Repo" },
      ]
    : [{ label: "+ Add Repo" }];

  const handleTaskClick = (taskLabel) => {
    if (taskLabel === "+ Add Repo") {
      setIsModalOpen(true);
    } else {
    }
  };

  useEffect(() => {
    return () => {
      getTask();
      if (task_id) {
        setLastVisitedChat(task_id); // Update the last visited chat on navigate away
      }
    };
  }, [task_id, setLastVisitedChat]);

  const bottomRef = useRef(null);

  const handleModelChange = async (newModel) => {
    setSelectedModel(newModel);

    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.put(
        `${API_URL}/select_model`,
        { task_id: task_id, model_name: newModel },
        {
          headers: headers,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  function resetStates() {
    setCards([]);
    setMessage("");
    setMessages([]);
    setSelectedDataSource(-1);
    setTask({});
    setAuthToken(null);
    setIsLoading(false);
    setLiveResponse(null);
    setIsReady(false); // Add this line
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAvailableRepos() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/repos`, {
        headers: headers,
      });

      if (response.data) {
        console.log("response.data", response.data);
        setAvailableRepos(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAvailableStudios() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/studio/seeds`, {
        headers: headers,
      });

      if (response.data) {
        console.log(response);
        setStudios(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getMenuOptions() {
    var studios = await getAvailableStudios();
    console.log("studios", studios);
    studios = studios.filter((studio) => studio.status === "READY");

    var options = [];

    var value = 1;

    for (let i = 0; i < studios.length; i++) {
      options.push({
        label: studios[i].display_name,
        value: value,
        type: "studio",
        data: studios[i],
      });
      value += 1;
    }

    setOptions(options);
    return options;
  }

  async function startTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.post(`${API_URL}/start_task`, null, {
        headers: headers,
      });
      resetStates(); // Add await here
      if (socket) {
        socket.disconnect();
      }
      navigate(`/chat/${response.data.task_id}`);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  async function stopTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.post(`${API_URL}/stop`, {task_id: task_id}, {
        headers: headers,
      });
      console.log(response);
      // append a new message to the messages state
      setMessages((prevMessages) => [...prevMessages, {
        role: "assistant",
        content: {
          type: "task_completed",
          data: "STOP",
        },
      }]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/chat/${task_id}`, {
        headers: headers,
      });

      if (response.data) {
        setTask(response.data);
        if (response.data.goa_plan.orchestrator) {
          setTaskList(response.data.goa_plan.orchestrator);
        }
        if (!messages) {
          setMessages(response.data.history);
          console.log("HEEY:", messages);
          console.log("Existing plan:", response.data.plan.steps);
        }
        setIsReady(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function approvePlan() {
    try {
      socket.emit(
        "message",
        JSON.stringify({
          task_id: task_id,
          action: "approve_plan",
          model_name: selectedModel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function rejectPlan() {
    try {
      socket.emit(
        "message",
        JSON.stringify({
          task_id: task_id,
          action: "reject_plan",
          model_name: selectedModel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  const sendMessage = (message) => {
    setMessage(message);
    if (typeof HockeyStack !== "undefined")
      HockeyStack.goal("Send Message", { message: message });
    console.log("Sending message:", message);
    if (message.trim() && socket && socket.connected) {
      // Add socket.connected check
      setIsLoading(true);
      const message_obj = {
        task_id: task_id,
        message: message,
        model_name: selectedModel,
      };
      const msg = {
        role: "user",
        content: { data: message, type: "chat_message" },
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      socket.emit("message", JSON.stringify(message_obj));
      setMessage("");
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "error",
          content: {
            data: "An error occurred while sending the message. Please try again later.",
            type: "error",
          },
        },
      ]);
    }
  };

  const createPRAction = () => {
    let message = "/apply";
    console.log("Sending message:", message);
    if (message.trim()) {
      setIsLoading(true);
      const message_obj = {
        task_id: task_id,
        message: message,
        model_name: selectedModel,
      };
      const msg = {
        role: "user",
        content: { data: message, type: "chat_message" },
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      socket.emit("message", JSON.stringify(message_obj));
      setMessage("");
    }
  };

  const hasNoMessages = !messages || messages?.length === 0;

  const handleOptionChange = (event) => {
    setSelectedDataSource(event.target.value);
  };

  async function confirmSelectedSource() {
    if (typeof HockeyStack !== "undefined")
      HockeyStack.goal("Task Start", { selected_repo: selectedDataSource });

    var selected = {};
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === selectedDataSource) {
        selected = options[i];
        break;
      }
    }

    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    try {
      if (selected.type === "repo") {
        const response = await axios.put(
          `${API_URL}/select_repo`,
          {
            task_id: task_id,
            repo_id: selected["id"],
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      } else if (selected.type === "studio") {
        const response = await axios.put(
          `${API_URL}/studio/select`,
          {
            task_id: task_id,
            studio_id: selected.data.id,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      }
      getTask();
    } catch (error) {
      console.log(error);
    }
  }

  const [planProposed, setPlanProposed] = useState(false);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    getTask();

    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (messages?.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.content.type === "plan") {
        if (typeof HockeyStack !== "undefined")
          HockeyStack.goal("Plan Proposed", { plan: lastMessage.content.data });
        setPlanProposed(true);
      } else {
        setPlanProposed(false);
      }
    }
  }, [messages]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  let studioFormat = task?.studio?.id && isLargeScreen;

  const chatInterface = (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        display: "flex",
        height: "100%",
        width: studioFormat ? "calc(100vw - 250px)" : "100vw",
        overflow: "hidden",
        marginLeft: studioFormat ? "240px" : "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: studioFormat ? "65%" : isLargeScreen ? "60%" : "100%",
          margin: studioFormat ? 0 : "auto",
        }}
      >
        <CssBaseline />
        {!isReady && <Loading />}
        {isReady && !task?.selected_repo?.id && !task?.studio?.id && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                width: "100vw",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                color="textSecondary"
                textAlign="center"
                padding="20px"
              >
                Please select a data source to start a task
              </Typography>
              <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
                <InputLabel>Select a repository</InputLabel>
                <Select
                  value={selectedDataSource}
                  onChange={handleOptionChange}
                  label="Select a repository"
                  sx={{ width: "300px" }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedDataSource > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={confirmSelectedSource}
                  sx={{ mt: 3, mb: 2, paddingY: 2 }}
                >
                  Start Task
                </Button>
              )}
            </Box>
          </Box>
        )}
        {task?.selected_repo?.id && (
          <Box sx={{ display: "flex", flexDirection: "column", height: "100%", position: "relative" }}>
          <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 0, paddingBottom: "60px" }}>
            <ChatMessages
              messages={messages}
              createPRAction={createPRAction}
              liveResponse={liveResponse}
              sendMessage={sendMessage}
            />
            <div ref={bottomRef} />
          </Box>
          {task?.selected_repo?.id && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "background.paper",
              }}
            >
              {planProposed ? (
                <PlanDecision
                  onYes={approvePlan}
                  onNo={rejectPlan}
                  isLoading={isLoading}
                />
              ) : task.stage === "PLAN_PENDING" ||
                task.stage === "CODE" ||
                (typeof messages[messages.length - 1]?.content?.data === "string" &&
                  messages[messages.length - 1].content.data.startsWith("/help")) ? (
                <WorkActivityIndicator />
              ) : (
                <Container sx={{ mx: 0, px: 0}}>
                  <ChatInput
                    onSendMessage={sendMessage}
                    isLoading={messages.length > 0 && messages[messages.length - 1]?.content?.type !== "task_completed"}
                    onModelChange={handleModelChange}
                    showModelSelect={false}
                    onStopGeneration={stopTask}
                  />
                </Container>
              )}
            </Box>
          )}
        </Box>
        )}

        {task?.studio?.id && (
          <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", position: "relative" }}>
          <Box sx={{ flexGrow: 1, overflowY: "auto", pb: "64px" }}> {/* Add bottom padding */}
            <ChatMessages
              messages={messages}
              createPRAction={createPRAction}
              liveResponse={liveResponse}
              sendMessage={sendMessage}
            />
            <div ref={bottomRef} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: "transparent",
            }}
          >
            <Container>
              <ChatInput
                onSendMessage={sendMessage}
                isLoading={messages.length > 0 && messages[messages.length - 1]?.content?.type !== "task_completed"}
                onModelChange={handleModelChange}
                showModelSelect={false}
                onStopGeneration={stopTask}
              />
            </Container>
          </Box>
        </Box>
        )}
      </Box>
      {studioFormat && (
        <Box
          sx={{
            width: "30%",
            height: "100vh",
            position: "fixed",
            right: 0,
            top: 0,
            padding: "10px",
            marginTop: "6px",
            marginRight: "6px",
          }}
        >
          {taskList?.length > 0 && <TaskList todoString={taskList} />}
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          autoHideDuration={10000}
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );

  const [activeTab, setActiveTab] = useState("Chat");

  return (
    <ThemeProvider theme={defaultTheme}>
      {activeTab == "Chat" && (
        <AppHeader
          user={user ? user : null}
          startTask={startTask}
          pos={studioFormat ? "left" : "top"}
        />
      )}
      {task?.studio?.id && (
        <>
          <FloatingTabSwitch
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabWithNotification={floatingTabNotification}
          />
          <div style={{ display: activeTab === "Studio" ? "block" : "none" }}>
            {<Studio studioId={task?.studio?.id} ref={studioRef} />}
          </div>
        </>
      )}
      <div style={{ display: activeTab === "Chat" ? "block" : "none" }}>
        {chatInterface}
      </div>
    </ThemeProvider>
  );
}
