import React, { useState } from 'react';
// import {Modal} from '@mui/material';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";
import { colors } from "@mui/material";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { ON_PREM } from "../globals";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://fumedev.com/">
        Fume Technologies, Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: colors.teal,
  },
});

export default function SignIn() {
  // State for controlling modal visibility

  const navigate = useNavigate();
  
  // const [openModal, setOpenModal] = useState(false);

  async function signIn(
    email,
    password,
  ) {
    // Attempt to sign up the user.
    let { data, error: signInError } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })

    // If there's an error in signing up, log it and alert the user.
    if (signInError) {
      console.log(signInError);
      alert("Incorrect login credentials.");
      return;
    }

    // Redirect to homepage on successful login
    navigate('/');
  }

   // Function to handle "Get Started" button click
   const handleGetStartedClick = () => {
    navigate('/')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signIn(
      data.get("email"),
      data.get("password"),
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            justifySelf: "center",
          }}
        >
          <img
            src="logo.png"
            style={{ maxWidth: "150px", marginBottom: "20px" }}
          />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {!ON_PREM && (
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                Don't have an account?{' '}
                <Link href="/signup" variant="body2">
                  Sign up
                </Link>
              </Typography>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
        <div>
          <br />
          <p>
            "Programming: where we tell computers to do things, they do it, and
            we're still surprised it worked."
          </p>
          <footer>- ChatGPT</footer>
        </div>
      </Container>
      {/* <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="success-modal-title" variant="h6" component="h2">
            We are so back.
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            Welcome back! Please note that Fume is still in public beta and improving every day.
          </Typography>
          <Button
            onClick={handleGetStartedClick}
            sx={{ mt: 3, mb: 2 }}
            variant="contained"
          >
            Let's Go
          </Button>
        </Box>
      </Modal> */}
    </ThemeProvider>
  );
}
