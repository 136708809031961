import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tabs, Tab } from "@mui/material";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
import io from "socket.io-client";
import { supabase } from "../../../supabaseClient";
import { API_URL } from "../../../globals";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "transparent",
    paddingRight: 10,
    fontFamily: "monospace",
    color: "#d4d4d4",
  },
  terminalContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    borderColor: "#2f2f2f",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    overflow: "hidden",
    minHeight: 0,
    position: "relative",
  },
  tabs: {
    marginBottom: 16,
  },
  fumeTerminal: {
    fontFamily: "monospace",
    fontSize: "10pt",
    whiteSpace: "pre-wrap",
    flexGrow: 1,
    overflowY: "auto",
    backgroundColor: "#0C0C0C",
    padding: 16,
    color: "#ffffff",
    borderColor: "#2f2f2f",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 16,
  },
  xtermjsTerminal: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
};

const TerminalWindow = ({ studioId, refreshStudio, fumeTerminalContent }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const terminalRef = useRef(null);
  const xtermRef = useRef(null);
  const fitAddon = useRef(new FitAddon());
  const socketRef = useRef(null);
  const resizeObserver = useRef(null);

  const initializeTerminal = useCallback(() => {
    if (!xtermRef.current) {
      xtermRef.current = new Terminal({
        cursorBlink: true,
        theme: {
          background: "#0C0C0C",
          foreground: "#ffffff",
        },
        scrollback: 1000,
      });
      xtermRef.current.loadAddon(fitAddon.current);
    }
  }, []);

  const connectSocket = useCallback(async () => {
    if (isConnected || socketRef.current) {
      console.log("Already connected or connecting");
      return;
    }

    console.log("Initiating new connection");
    setIsConnected(true);

    try {
      const token = (await supabase.auth.getSession()).data.session.access_token;

      socketRef.current = io(`${API_URL}/ssh`, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });

      socketRef.current.on('connect', () => {
        console.log("Connected to SSH server");
        xtermRef.current.write("Connected to SSH server...\r\n");
        socketRef.current.emit('ssh_connect', { studio_id: studioId });
      });

      socketRef.current.on('output', (data) => {
        xtermRef.current.write(data);
      });

      socketRef.current.on('disconnect', () => {
        console.log("Disconnected from SSH server");
        xtermRef.current.write("\r\nDisconnected from SSH server...\r\n");
        setIsConnected(false);
      });

      xtermRef.current.onData(data => {
        if (socketRef.current) {
          socketRef.current.emit('input', data);
        }
      });
    } catch (error) {
      console.error("Error connecting to SSH server:", error);
      setIsConnected(false);
    }
  }, [isConnected, studioId]);

  const disconnectSocket = useCallback(() => {
    if (socketRef.current) {
      console.log("Disconnecting socket");
      socketRef.current.disconnect();
      socketRef.current = null;
      setIsConnected(false);
    }
  }, []);

  useEffect(() => {
    initializeTerminal();
    
    return () => {
      disconnectSocket();
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [initializeTerminal, disconnectSocket]);

  useEffect(() => {
    if (activeTab === 1) {
      if (!isConnected) {
        connectSocket();
      }
      
      if (terminalRef.current && xtermRef.current) {
        const terminalElement = document.createElement('div');
        terminalElement.style.height = '100%';
        terminalElement.style.width = '100%';
        terminalRef.current.innerHTML = ''; // Clear previous content
        terminalRef.current.appendChild(terminalElement);
        
        xtermRef.current.open(terminalElement);
        fitAddon.current.fit();

        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }

        resizeObserver.current = new ResizeObserver(() => {
          fitAddon.current.fit();
        });

        resizeObserver.current.observe(terminalRef.current);
      }
    }

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [activeTab, isConnected, connectSocket]);

  useEffect(() => {
    if (fumeTerminalContent) {
      setActiveTab(0);
    }
  }, [fumeTerminalContent]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={styles.root}>
      <Tabs value={activeTab} onChange={handleTabChange} style={styles.tabs}>
        <Tab label="Fume's Terminal" />
        <Tab label="Your Terminal" />
      </Tabs>
      {activeTab === 0 ? (
        <div
          style={styles.fumeTerminal}
          dangerouslySetInnerHTML={{ __html: fumeTerminalContent || "" }}
        />
      ) : (
        <div style={styles.terminalContainer}>
          <div style={styles.xtermjsTerminal} ref={terminalRef}></div>
        </div>
      )}
    </div>
  );
};

export default TerminalWindow;