// WorkActivityIndicator.js
import React from 'react';
import { Typography, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';

const WorkActivityIndicator = () => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pt: 4,
        pb: 4,
        justifyContent: 'center',
        width: '100vw',
      }}
    >
      <Typography sx={{ mb: 2, color: 'white' }}>
        Fume is working right now! Check back in 5-10 minutes.
      </Typography>
    </Paper>
  );
};

export default WorkActivityIndicator;