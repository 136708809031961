import React, { useEffect, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CircularProgress from "@mui/material/CircularProgress";
import { API_URL } from "../../../globals";
import { supabase } from "../../../supabaseClient";

const FileExplorer = ({ studioId, changeEditor, refresh }) => {
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [data, setData] = useState({});
  const [loadingFolder, setLoadingFolder] = useState(null);

  const fetchFolderTree = async (folderPath = "") => {
    setLoadingFolder(folderPath);
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    const response = await fetch(`${API_URL}/studio/folder-tree?studio_id=${studioId}&folder_path=${folderPath}`, {
      headers: headers,
    });
    const folderData = await response.json();
    setLoadingFolder(null);
    return folderData;
  };

  useEffect(() => {
    console.log("refreshing");
    fetchFolderTree().then(setData);
  }, [refresh, studioId]);

  const toggleFolder = async (folderPath) => {
    if (expandedFolders.includes(folderPath)) {
      setExpandedFolders(expandedFolders.filter((path) => path !== folderPath));
    } else {
      setExpandedFolders([...expandedFolders, folderPath]);
      
      // Fetch the folder contents when expanding
      const folderData = await fetchFolderTree(folderPath);
      
      // Update the data state by replacing only the contents of the clicked directory
      setData(prevData => {
        const updateFolderContents = (obj, path, newContents) => {
          const pathArray = path.split('/').filter(Boolean);
          let current = obj;

          // Navigate to the parent of the target folder
          for (let i = 0; i < pathArray.length - 1; i++) {
            if (!current[pathArray[i]] || !current[pathArray[i]].contents) {
              return obj; // Path doesn't exist, return original object
            }
            current = current[pathArray[i]].contents;
          }

          // Update the contents of the target folder
          const targetKey = pathArray[pathArray.length - 1];
          if (current[targetKey]) {
            current[targetKey] = {
              ...current[targetKey],
              contents: newContents
            };
          }

          return { ...obj };
        };
        
        return updateFolderContents(prevData, folderPath, folderData);
      });
    }
  };

  const handleFileClick = (filePath) => {
    console.log(`Clicked file: ${filePath}`);
    changeEditor(filePath);
  };

  const isFile = (value) => {
    return value && value.hasOwnProperty('contents') && Object.keys(value.contents).length === 0;
  };

  const renderTree = (node, depth = 0, parentPath = '') => {
    if (!node || typeof node !== 'object') return null;
    
    return Object.entries(node).map(([key, value]) => {
      if (!value || typeof value !== 'object') return null;
      
      const fullPath = parentPath ? `${parentPath}/${key}` : key;
      const isFileItem = isFile(value);
      const isExpanded = expandedFolders.includes(fullPath);
      const isLoading = loadingFolder === fullPath;
      
      return (
        <div key={fullPath}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "4px 0",
              marginLeft: depth * 10,
              cursor: "pointer",
            }}
            onClick={() => (isFileItem ? handleFileClick(fullPath) : toggleFolder(fullPath))}
          >
            {isFileItem ? (
              <>
                <InsertDriveFileIcon style={{ marginRight: "5px", color: "#8f8f8f" }} />
                {key}
              </>
            ) : (
              <>
                {isExpanded ? (
                  <FolderOpenIcon style={{ marginRight: "5px", color: "#ffca28" }} />
                ) : (
                  <FolderIcon style={{ marginRight: "5px", color: "#ffca28" }} />
                )}
                {key}
                {isLoading && (
                  <CircularProgress size={16} style={{ marginLeft: "5px", color: "#fff" }} />
                )}
              </>
            )}
          </div>
          {!isFileItem && isExpanded && renderTree(value.contents, depth + 1, fullPath)}
        </div>
      );
    }).filter(Boolean);
  };

  return (
    <div style={{ maxHeight: "90vh", overflowY: "auto"}}>
      {renderTree(data['.']?.contents, 0, '.')}
    </div>
  );
};

export default FileExplorer;