import React from 'react';

const LoadingPage = ({message}) => {
  if (!message){
    message = "Loading..."
  }
  return (
    <div className="loading-container">
      <div className="loading-content">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
        </div>
        <p className="loading-text">{message}</p>
      </div>
    </div>
  );
};

const styles = `
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(151, 208, 204, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(151, 208, 204, 0); }
    100% { box-shadow: 0 0 0 0 rgba(151, 208, 204, 0); }
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #121212;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #121212;
  }

  .loading-content {
    text-align: center;
    animation: fadeIn 0.5s ease-out;
  }

  .loader {
    position: relative;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    background-color: rgba(151, 208, 204, 0.1);
    border-radius: 50%;
    animation: pulse 2s infinite;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: #97D0CC;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  .loading-text {
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 300;
  }
`;

export default function App() {
  return (
    <>
      <style>{styles}</style>
      <LoadingPage />
    </>
  );
}