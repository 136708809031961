import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
  Typography,
  Paper,
} from '@mui/material';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#1e1e1e',
    },
  },
  typography: {
    fontSizeNormal: '1.1rem',
    fontSizeSmall: '0.95rem',
  },
});

const TaskList = ({ todoString }) => {
  const [tasks, setTasks] = useState([]);
  const [expandedTasks, setExpandedTasks] = useState({});

  useEffect(() => {
    if (!todoString) {
      setTasks([]);
      return;
    }

    const parseTodoString = (str) => {
      if (str.length === 0) {
        return "";
      }
      str = str?.replaceAll('```markdown', '```');
      str = str?.split('```')[1];

      const lines = str?.split('\n');
      let currentSection = null;
      const parsedTasks = [];

      lines?.forEach(line => {
        if (line.startsWith('*') && line.endsWith(':*') || line.startsWith('### ')) {
          currentSection = {
            title: line.startsWith('*') ? line.slice(1, -2).trim() : line.slice(4).trim(),
            steps: []
          };
          parsedTasks.push(currentSection);
        } else if (line.trim().startsWith('*')) {
          const taskContent = line.trim().slice(1).trim();
          const isDone = taskContent.startsWith('[X]');
          const title = isDone ? taskContent.slice(3).trim() : taskContent.slice(3).trim();
          
          if (currentSection && currentSection.title !== 'Current Focus') {
            currentSection.steps.push({ title, done: isDone });
          } else if (!currentSection) {
            parsedTasks.push({ title, done: isDone, steps: [] });
          }
        } else if (line.trim() && currentSection) {
          if (currentSection.title === 'Current Focus') {
            currentSection.content = line.trim();
          } else {
            currentSection.steps.push({ title: line.trim(), done: false });
          }
        }
      });

      return parsedTasks;
    };

    const parsedTasks = parseTodoString(todoString);
    setTasks(parsedTasks);
    
    // Set all sections to be expanded by default, including 'Current Focus'
    const initialExpandedState = {};
    parsedTasks.forEach((task, index) => {
      initialExpandedState[index] = true;
    });
    setExpandedTasks(initialExpandedState);
  }, [todoString]);

  const toggleTask = (taskIndex) => {
    setExpandedTasks(prev => ({
      ...prev,
      [taskIndex]: !prev[taskIndex]
    }));
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper 
        elevation={3}
        sx={{
          height: 'calc(100% - 20px)',
          marginBottom: '20px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" sx={{ p: 2, fontWeight: 'bold' }}>
          Plan
        </Typography>
        {tasks.length === 0 ? (
          <Typography sx={{ p: 2, color: 'text.secondary' }}>
            You will see Fume's plan and progress here!
          </Typography>
        ) : (
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflowY: 'auto', flex: 1 }}>
            {tasks.map((task, index) => (
              <React.Fragment key={index}>
                <ListItem 
                  disablePadding
                  secondaryAction={
                    <Typography component="span" sx={{ fontSize: darkTheme.typography.fontSizeNormal }}>
                      {expandedTasks[index] ? <ExpandMore /> : <ChevronRight />}
                    </Typography>
                  }
                >
                  <ListItemButton onClick={() => toggleTask(index)} dense>
                    <ListItemText 
                      primary={task.title}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: darkTheme.typography.fontSizeNormal,
                          fontWeight: 'bold',
                          color: 'text.primary',
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Collapse in={expandedTasks[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {task.title === 'Current Focus' ? (
                      <ListItem sx={{ pl: 4 }}>
                        <ListItemText
                          primary={task.content}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: darkTheme.typography.fontSizeSmall,
                              color: 'text.primary',
                            }
                          }}
                        />
                      </ListItem>
                    ) : (
                      task.steps.map((step, stepIndex) => (
                        <ListItem key={stepIndex} sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={step.done}
                              tabIndex={-1}
                              disableRipple
                              size="small"
                              sx={{
                                color: step.done ? 'success.main' : 'action.active',
                                '&.Mui-checked': {
                                  color: 'success.main',
                                },
                                '& .MuiSvgIcon-root': { fontSize: 24 },
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={step.title}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: darkTheme.typography.fontSizeSmall,
                                textDecoration: step.done ? 'line-through' : 'none',
                                color: step.done ? 'text.secondary' : 'text.primary',
                              }
                            }}
                          />
                        </ListItem>
                      ))
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default TaskList;