import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../globals";
import { Typography, CircularProgress, Alert, Box, Paper } from "@mui/material";
import { supabase } from "../supabaseClient";

const GithubCallback = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const repo_owner = urlParams.get("repo_owner");
    const repo_name = urlParams.get("repo_name");

    let isMounted = true;
    let axiosSource = axios.CancelToken.source();

    const fetchData = async () => {
      if (code) {
        // Retrieve form data from localStorage
        const storedFormData = JSON.parse(localStorage.getItem("formData"));

        if (storedFormData) {
          console.log("storedFormData", storedFormData);
          const { gitProvider, organizationName, repositoryName, developmentBranch } = storedFormData;
          const user = await supabase.auth.getUser();
          const userEmail = user.data.user.email;


          // Send a request to the backend with the authorization code
          try {
            const response = await axios.get(
              `${API_URL}/github/callback?code=${code}&repo_owner=${organizationName}&repo_name=${repositoryName}&branch_name=${developmentBranch}&user_email=${userEmail}`,
              { cancelToken: axiosSource.token }
            );

            if (response.data.permission) {
              // If permission is true, submit the repo
              const authToken = (await supabase.auth.getSession()).data.session.access_token;
              const headers = { Authorization: `Bearer ${authToken}` };
              const body = {
                git_provider: gitProvider,
                repo_name: `${organizationName}/${repositoryName}`,
                branch: developmentBranch,
                gitlab_token: "",
                gitlab_domain: "",
              };
              if (isMounted) navigate("/settings");
            
            } else {
              if (isMounted) setError(response.data.message || "GitHub verification failed.");
            }
          } catch (error) {
            if (isMounted) {
              console.error("Error during GitHub verification:", error);
              setError("An error occurred during GitHub verification.");
            }
          }
        } else {
          if (isMounted) {
            setError("Form data not found.");
            setLoading(false);
          }
        }
      } else {
        if (isMounted) {
          setError("Authorization code not found.");
          setLoading(false);
        }
      }

      if (isMounted) setLoading(false);
    };

    fetchData();

    return () => {
      isMounted = false;
      axiosSource.cancel("Cancelling in cleanup");
    };
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        p: 4,
      }}
    >
      <Paper elevation={3} sx={{ p: 4, borderRadius: 4, textAlign: "center", maxWidth: 400, width: "100%", background: "transparent" }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {loading ? (
            <>
              <Typography variant="h5" gutterBottom>
                Adding the repository to your account...
              </Typography>
              <CircularProgress sx={{ mt: 2 }} />
            </>
          ) : error ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ) : (
            <Typography variant="h5" gutterBottom>
              Repository added successfully!
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default GithubCallback;