import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import axios from "axios";

export default function LinkPreviewCard({ url }) {
  const [previewData, setPreviewData] = useState(null);
  const [fetchError, setFetchError] = useState(false); // Added a state to track fetch error
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchPreviewData = async () => {
      try {
        const apiKey = "84b8c89b-a442-4886-83a8-4c392b36006c";
        const response = await axios.get(
          `https://opengraph.io/api/1.1/site/${encodeURIComponent(
            url
          )}?app_id=${apiKey}`
        );
        const data = response.data;
        setPreviewData({
          title: data.hybridGraph.title,
          image: data.hybridGraph.image,
          description: data.hybridGraph.description,
        });
        setFetchError(false); // Clear any previous errors
      } catch (error) {
        console.error("Error fetching preview data:", error);
        setFetchError(true); // Set error state to true
      }
    };

    fetchPreviewData();
  }, [url]);

  const handleViewPR = () => {
    // Open the PR link in a new tab
    window.open(url, "_blank");
  };

  // If fetch error, show the 'View Pull Request' button instead of loading animation
  if (fetchError) {
    return;
  }

  if (!previewData) {
    return <div>Loading preview...</div>;
  }

  const { title, image, description } = previewData;

  return (
    <Box key={url} sx={{ width: "100%", mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
      </Typography>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box sx={{ flex: 1, mb: isMobile ? 2 : 0 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ mb: 1, color: "white", textDecoration: "none", fontSize: "12pt" }}
          >
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {title}
            </a>
          </Typography>
          <Typography variant="body2">{url}</Typography>
          <Typography variant="body">{description}</Typography>
        </Box>
        {image && (
          <Box sx={{width: 120}}>
            <img
              src={image}
              alt="Pull Request Preview"
              style={{ width: "100%", height: "auto", borderRadius: 5 }}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}
